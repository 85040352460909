import React, { useEffect, useMemo, useRef, useState } from 'react';

import { DEVICE_SIZE, mq } from '@marty-js/design/src/utils/mq';
import { useTranslation } from '@marty-js/design/src/utils/translation';
import { GenericItem } from '@marty-js/api-sdk/types';
import styled, { css } from 'styled-components';
import { PostItemLine } from '@marty-js/design/src/molecules/post-item-line';
import { SectionNavCloud } from '@marty-js/design/src/molecules/section-nav-cloud';
import { GetGenericItemBySectionVariables } from '@marty-js/api-sdk/__generated__/GetGenericItemBySection';
import { useGetGenericItemBySection } from '@marty-js/api-sdk/services/genericItemBySection';
import { SectionSelectionComponent } from '@marty-js/design/src/molecules/section-selection';
import { Newsletter } from '@marty-js/design/src/molecules/newsletter';
import { ItemCtaSimple } from '@marty-js/design/src/molecules/item-cta-simple';
import { HeadingTwo } from '@marty-js/design/src/atoms/h2';
import { LinkList } from '@marty-js/design/src/molecules/link-list';
import { PostsList } from '../../../layout/post/posts-list';
import { formatDate } from '../../../atoms/date/utils';
import { Container, FullContent, MainContent, SideContent } from '../../../atoms/grid-container';
import { Ad } from '../../../ads/ad';
import { Breadcrumb } from '../../../atoms/breadcrumb';
import { SlateContentPlugin } from '../../../article-processor/plugins/slate.content';
import { Cell } from '../../../article-processor/types';
import { useMetaSeoContext, useSetMetaTitleIfNull, useSetPageNumber } from '../../../utils/metaSeoContext';
import editorSlateToText from '../../../utils/editorSlateToText';
import { Paginator } from '../../../atoms/paginator';
import { useInfiniteScroll } from '../../../utils/infinite-scroll.hooks';

import type {
  LayoutSectionProps,
  PluginArticleProcessorLight,
  PluginGenericItemByQueryConfig,
  PluginSectionInserts,
  PluginSectionList,
  PluginSectionSelection,
} from '../types';
import { LinkComponentHref } from '../../../types';

const DesktopOnly = styled.div`
  ${mq.lte(
    DEVICE_SIZE.LARGE,
    css`
      display: none;
    `,
  )}
`;

const SideContentBottom = styled.div`
  display: none;

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      display: block;
      position: sticky;
      top: 10px;
      margin-top: 30px;
    `,
  )}
`;

const Title = styled.span`
  color: ${(props) => (props.theme.isDark ? props.theme.palette.white : props.theme.palette.black)};
  display: block;
  font-family: ${(props) => props.theme.typography.primaryFont};
  font-size: 18px;
  font-weight: bold;
  margin: 15px 0;

  &:first-of-type {
    margin: 30px 0 20px;
  }

  ${mq.gte(
    DEVICE_SIZE.LARGE,
    css`
      font-size: 26px;
    `,
  )}
`;

const ListItemRow = styled.ul`
  display: flex;
  flex-direction: column;
`;

const Introduction = styled.div`
  margin: 0 0 var(--spacer-m-fluid);
`;

export type DefaultSectionData = {
  mainFlux: PluginGenericItemByQueryConfig;
  textJson: PluginArticleProcessorLight;
  inserts?: PluginSectionInserts;
  navigation?: PluginSectionList;
  sectionSelection?: PluginSectionSelection;
  topDownload: any;
};

export const Default: React.FC<LayoutSectionProps<DefaultSectionData>> = ({ section, data, breadcrumb, pageInfo }) => {
  const t = useTranslation();
  const [genericItems, setGenericItems] = useState<GenericItem[]>(data.mainFlux?.genericItemList);
  const size = data.mainFlux?.info?.size ?? 10;
  const introductionRef = useRef<HTMLParagraphElement>(null);
  const refSideBar = useRef<HTMLElement>();
  const variables: GetGenericItemBySectionVariables = useMemo(
    () => ({
      sectionId: pageInfo?.section?.id,
      from: 0,
      size,
    }),
    [pageInfo?.section?.id, size],
  );

  const { fetchMoreData, fetchData, result, loading, called } = useGetGenericItemBySection({ variables });

  const detectorRef = useInfiniteScroll(() => {
    const from = genericItems.length;
    if (called) {
      fetchMoreData({ from });
    } else if (!loading) {
      fetchData({ variables: { ...variables, from } });
    }
  });

  useEffect(() => {
    if (!loading) {
      if (result) {
        setGenericItems([...data.mainFlux.genericItemList, ...result.genericItemBySection.genericItems]);
      }
    }
  }, [setGenericItems, result, loading, data.mainFlux, pageInfo.section.url]);

  const hatContent: Cell = data?.textJson?.articleJson;

  const navigation = data?.navigation?.sectionList || null;
  const sectionSelection = data?.sectionSelection?.data || null;

  useSetMetaTitleIfNull(t('sdk.template.simple_listing.metaTitle', { title: section.title }));
  useSetPageNumber(pageInfo.pageNumber);

  const metaSeoContext = useMetaSeoContext();

  if (!metaSeoContext.description && hatContent) {
    metaSeoContext.description = editorSlateToText(hatContent).replace(/(^.{180}.+?)\..+/g, '$1...');
  }

  if (!metaSeoContext.description) {
    metaSeoContext.description = t('sdk.template.simple_listing.metaDescription', { title: section.title });
  }

  // const h1 = metaSeoContext.titleH1 ?? section.title;

  return (
    <Container>
      <Ad className="Billboard_1" desktopOnly noBackground megaban />
      <MainContent>
        <Breadcrumb breadcrumb={breadcrumb} />
      </MainContent>
      <FullContent className="mod-spacer-top-xl">
        {navigation ? <SectionNavCloud navLinks={navigation} /> : null}
        {hatContent ? (
          <Introduction ref={introductionRef} data-testid="hat-content">
            <SlateContentPlugin cell={hatContent} />
          </Introduction>
        ) : null}
      </FullContent>
      <MainContent>
        <PostsList>
          {genericItems?.map((genericItem: GenericItem) => {
            const link = {
              href: genericItem.url,
            };

            const isSponsoredItem = genericItem?.tagSlugs?.includes('sponsored');

            return (
              <PostItemLine
                id={genericItem.id}
                key={genericItem.id}
                title={genericItem.title}
                commentCount={genericItem.commentsNb}
                image={{ imageId: genericItem.imageId, alt: genericItem.title }}
                date={formatDate(genericItem.republishedAt, 'dd MMMM yyyy', t('locale'))}
                authors={genericItem.authors}
                link={link}
                sponsoredItem={isSponsoredItem}
              />
            );
          })}
        </PostsList>
        <div ref={detectorRef} aria-hidden />
        {data.mainFlux?.info ? <Paginator baseUrl={pageInfo.section.url} info={data.mainFlux.info} mode="dir" /> : null}
      </MainContent>
      <SideContent className="mod-gapped" ref={refSideBar}>
        {sectionSelection && (
          <SectionSelectionComponent
            title={sectionSelection?.info?.title}
            sections={sectionSelection?.sections?.sectionList}
          />
        )}
        <Ad className="HalfpageAd_1" desktopOnly />
        <Newsletter />
        <Ad className="HalfpageAd_2" desktopOnly />
        <DesktopOnly>
          {data.topDownload?.data ? (
            <div>
              <Title>{data.topDownload?.data?.info?.title}</Title>
              <ListItemRow data-testid="top-download">
                {data.topDownload?.data?.products?.productVariantList?.map((product: any) => {
                  const imageProps = {
                    imageId: product.imageId,
                    alt: product.title,
                  };

                  const LinkProps = {
                    href: product.urlCta ?? product.url,
                    text: t('sdk.template.download.download'),
                  };

                  return (
                    <ItemCtaSimple
                      small={false}
                      href={product.url}
                      key={product.id}
                      title={product.title}
                      image={imageProps}
                      cta={LinkProps}
                      subtitle={product.title}
                    />
                  );
                })}
              </ListItemRow>
            </div>
          ) : null}
        </DesktopOnly>
        {data?.trends && (
          <div>
            {data?.trends?.data?.info?.title ? (
              <HeadingTwo className="mod-without-mt" as="div">
                {data.trends.data.info?.title}
              </HeadingTwo>
            ) : null}
            <ListItemRow>
              {data?.trends?.data?.links?.data?.map((link: LinkComponentHref, key: number) => {
                return <LinkList key={key} title={link.title} url={link.url} />;
              })}
            </ListItemRow>
          </div>
        )}
        <SideContentBottom>
          <Ad className="HalfpageAd_3" desktopOnly />
        </SideContentBottom>
      </SideContent>
    </Container>
  );
};

export default Default;
